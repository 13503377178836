const Data = [
  {
    image: require("./property-images/camino.jpg"),
    location: "O Camino del Monte",
    info: "Build your dream home on beautiful Catalina Island, 4400 sq ft with top level having view of bay",
    price: "$575,000",
    status: "Active, contact for more info",
  },
  {
    image: require("./property-images/clarissa.jpg"),
    location: "210 Clarissa",
    info: "Represented the buyer",
    price: "$1,050,000",
    status: "Closed December 1, 2022",
  },
  {
    image: require("./property-images/307sumner.JPG"),
    location: "307 Sumner Ave",
    info: "Represented both sellers and buyers",
    price: "$1,450,000",
    status: "Closed May 10, 2022",
  },
];

export default Data;
