export function AboutMe() {
  return (
    <div className=" md:grid md:grid-cols-2 md:grid md:pt-20 md:pl-36">
      <div className="flex justify-center md:order-1">
        <img
          src="./images/mark-phelps.png"
          alt="mark phelps"
          className="max-w-none h-52 md:h-auto"
        />
      </div>
      <div className="md:mt-40">
        <h1 className="text-[#284B63] drop-shadow-lg font-bold text-3xl md:text-6xl uppercase pb-3 pt-5">
          About Me
        </h1>
        <p className="xl:text-2xl">
          My name is Mark Phelps, I am a licensed real estate agent with
          Hamilton Cove Real Estate on Catalina Island, California. Having been
          born on the island and lived here for 35 years I feel I am uniquely
          qualified to make your real estate dreams become a reality. I have
          been in the hospitality field my entire career and currently give
          visitors tours all over the island, keeping me very much in tune with
          the pulse of both the people visiting and the local population.
        </p>
      </div>
    </div>
  );
}
