import { Listings } from "./Listings";
import { Route, Routes, Link } from "react-router-dom";

export function Home() {
  return (
    <>
      <div className="w-full">
        <img
          src="./images/catalina.jpg"
          className="w-full h-full object-cover absolute -z-10 brightness-50"
        ></img>
        <h1 className="text-white font-bold drop-shadow-lg uppercase text-6xl p-5 pt-10 pb-1 md:text-center md:pt-64 ">
          Catalina Island Real Estate
        </h1>
        <p className=" text-white text-2xl ml-5 pt-2 pb-5 mb-8 md:text-center mr-5">
          Working with hamilton cove real estate to bring you avalon homes for
          sale
        </p>
        <div className="md:text-center">
          <Link
            className="bg-white hover:gray text-[#284B63] font-bold py-2 px-4 border-b-4 border-gray hover:grey rounded-white ml-5"
            to="/avalon-homes-for-sale"
          >
            Avalon Homes For Sale
          </Link>
        </div>
      </div>
    </>
  );
}
