export function Contact() {
  return (
    <div className="p-5 md:flex md:justify-center md:mt-56">
      <div className="">
        <h1 className="text-[#284B63] drop-shadow-lg font-bold text-3xl md:text-6xl uppercase pb-3 pt-5">
          Contact
        </h1>
        <ul className="text-2xl">
          <li className="font-bold">Hamilton Cove Real Estate</li>
          <li>
            <a href="tel:7144704653">714-470-4653</a>
          </li>
          <li>
            <a href="mailto:mphelpsy95@yahoo.com">mphelpsy95@yahoo.com</a>
          </li>
        </ul>
      </div>
    </div>
  );
}
