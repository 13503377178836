import { Property } from "./Components/Property";
import Data from "./Data";

export function Listings() {
  const Properties = Data.map((data) => {
    return (
      <Property
        image={data.image}
        location={data.location}
        info={data.info}
        price={data.price}
        status={data.status}
      />
    );
  });

  return (
    <div className="p-5">
      <h1 class="text-[#284B63] uppercase drop-shadow-lg text-3xl md:text-7xl md:pl-12 font-bold pt-3">
        Avalon homes for sale
      </h1>
      <h1 className=" md:p-10 md:gap-5 md:justify-center">
        <div className="md:grid md:gap-5 grid-cols-4 ">{Properties}</div>
      </h1>
    </div>
  );
}
