import "./App.css";
import { Route, Routes, Link } from "react-router-dom";
import { Home } from "./Home";
import { Listings } from "./Listings";
import { AboutMe } from "./AboutMe";
import { Contact } from "./Contact";

function App() {
  return (
    <>
      <nav className="pl-5 p-1 md:flex md:justify-between md:p-3 md:ml-10 md:mr-10">
        <div>
          <h1 className="uppercase font-bold text-2xl">Mark Phelps</h1>
        </div>

        <ul className="flex mt-1 gap-3 md:gap-10 uppercase">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/avalon-homes-for-sale">Listings</Link>
          </li>
          <li>
            <Link to="/about-me">About Me</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/avalon-homes-for-sale" element={<Listings />} />
        <Route path="/about-me" element={<AboutMe />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </>
  );
}

export default App;
