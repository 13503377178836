export function Property(props) {
  return (
    <div className="container rounded drop-shadow-xl bg-white mb-10 p-1">
      <img
        className="aspect-square rounded w-full drop-shadow-xl object-cover"
        src={props.image}
        alt="property image"
      ></img>

      <div className="p-5">
        <h1 className="font-bold text-2xl mb-2">{props.location}</h1>
        <h2 className="pb-10">{props.info}</h2>
        <h2 className="font-bold text-3xl text-[#284B63]">{props.price}</h2>
        <p className="font-bold text-xl">{props.status}</p>
      </div>
    </div>
  );
}
